<template>
  <div class="planner bg-app vh-100">
    <v-container fluid class="px-0">
      <v-row v-if="!loading">
        <v-col class="px-2">
          <v-card flat class="transparent">
            <div class="px-4 mx-1 mb-6">
              <div class="d-flex align-center">
                <v-btn small icon class="mr-2" @click="$router.push('/planners')">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div>
                  <span class="overline">Planner</span>
                  <v-chip
                    :color="statusColor(currentStatusCode)"
                    x-small
                    text-color="white"
                    class="ml-2 text-uppercase"
                  >
                    {{ currentStatusText }}
                  </v-chip>
                  <h2 class="mb-2" style="margin-top: -10px">{{ planner.name ? planner.name : "Planner" }}</h2>
                </div>
              </div>
              <div class="subtitle-1">{{ planner.description }}</div>
              <div class="ma-0 pa-0" v-if="dueDate?.dueDateTime">
                <div class="d-flex align-center">
                  <span class="text-subtitle-2 mr-2">Due:</span>
                  <span> {{ formatDateTime(dueDate.dueDateTime) }} </span>
                </div>
              </div>
              <v-divider class="my-4"></v-divider>
              <div v-for="(task, taskIndex) in tasks" :key="'stepItem-' + taskIndex" class="mb-8">
                <div>
                  <div>
                    <div class="overline" style="line-height: 12px">Task {{ taskIndex + 1 }}</div>
                    <h3 :class="{ 'text-decoration-line-through': task.completed }" class="pb-2">{{ task.name }}</h3>
                  </div>
                </div>
                <div class="pb-2">{{ task.description }}</div>
                <div><span class="overline">Expected duration:</span> {{ task.duration }} hour</div>
                <div></div>
                <div v-if="task.prerequisites?.length">
                  <label class="overline">prerequisites</label>
                  <div class="mb-3">
                    <ul>
                      <li v-for="(p, idx) in task.prerequisites" :key="'p-' + idx">
                        {{ p }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="task.resources && task.resources.length && task.resources[0].name">
                  <label class="overline">Recommended resources</label>
                  <ul>
                    <li v-for="(r, idx) in task.resources" :key="'r-' + idx">{{ r.name }}</li>
                  </ul>
                </div>
                <div class="pt-3">
                  <div v-for="(hazard, hazardIndex) in task.hazards" :key="'hazardItem-' + hazardIndex">
                    <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                      <div v-if="hazard.type && type.name">
                        <span class="text-capitalize">
                          <label class="overline" style="line-height: 12px">
                            <v-icon color="orange darken-2" small>mdi-alert-box</v-icon
                            ><span class="pl-2" style="position: relative; top: 1px">Risk {{ subTypeIndex + 1 }}</span>
                          </label>
                          <h4>{{ type.name }} : {{ hazard.type }}</h4>
                        </span>
                        <div class="ml-6 mt-2">
                          <v-row class="mt-2 mb-4">
                            <v-col cols="auto" class="text-center">
                              <v-progress-circular
                                :value="getRiskRating(type.likelihood, type.impact)"
                                :size="150"
                                :width="15"
                                color="yellow darken-2"
                              >
                                <v-row justify="center" class="fill-height">
                                  <v-col class="text-center">
                                    <strong>{{ Math.ceil(getRiskRating(type.likelihood, type.impact)) }}%</strong>
                                    <div class="caption">Risk Rating</div>
                                  </v-col>
                                </v-row>
                              </v-progress-circular>
                            </v-col>

                            <v-col class="d-flex flex-column justify-center">
                              <v-progress-linear
                                rounded
                                striped
                                class="mb-4"
                                :color="setTrackColor(type.likelihood) + ' darken-4'"
                                :value="type.likelihood != 0 ? Math.ceil((type.likelihood / 5) * 100) : 5"
                                height="15"
                              >
                                <span class="overline">Likelihood</span>
                              </v-progress-linear>

                              <v-progress-linear
                                rounded
                                striped
                                class="mb-4"
                                :color="setTrackColor(type.impact) + ' darken-4'"
                                :value="Math.ceil((type.impact / 5) * 100)"
                                height="15"
                              >
                                <span class="overline">Impact</span>
                              </v-progress-linear>

                              <v-progress-linear
                                rounded
                                striped
                                class="mb-4"
                                :color="setTrackColor(type.severity) + ' darken-4'"
                                :value="type.severity != 0 ? Math.ceil((type.severity / 5) * 100) : 5"
                                height="15"
                              >
                                <span class="overline">Severity</span>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                          <label class="overline">Training Required</label>
                          <div class="mb-3">
                            <ul>
                              <li v-for="item in splitCommaString(type.requiredTraining)" :key="item">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                          <div v-if="requiresSafetyCompliance">
                            <v-checkbox
                              v-model="type.trainingConfirmed"
                              label="Required training completed"
                              class="mb-2"
                              dense
                              hide-details
                              :disabled="isCompleted"
                            ></v-checkbox>
                          </div>
                          <label class="overline">Risk Controls</label>
                          <div class="mb-3">
                            <ul>
                              <li v-for="item in splitCommaString(type.controlMeasures)" :key="item">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                          <div v-if="requiresSafetyCompliance" class="mt-2 mb-4">
                            <v-checkbox
                              v-model="type.controlMeasuresReady"
                              label="Risk control measures ready"
                              class="mb-2"
                              dense
                              hide-details
                              :disabled="isCompleted"
                            ></v-checkbox>
                          </div>
                          <label class="overline">Strategies to reduce risk</label>
                          <div class="mb-3">
                            <ul>
                              <li v-for="item in splitCommaString(type.mitigationStrategies)" :key="item">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                          <div v-if="requiresSafetyCompliance" class="mt-2 mb-4">
                            <v-checkbox
                              v-model="type.mitigationApplied"
                              label="Mitigation strategies applied"
                              class="mb-2"
                              dense
                              hide-details
                              :disabled="isCompleted"
                            ></v-checkbox>
                          </div>
                          <label class="overline">PPE (Personal Protective Equipment)</label>
                          <div class="mb-3">
                            <ul>
                              <li v-for="item in splitCommaString(type.ppe)" :key="item">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                          <div v-if="requiresSafetyCompliance" class="mt-2 mb-4">
                            <v-checkbox
                              v-model="type.ppeInspected"
                              label="PPE inspected and worn"
                              class="mb-2"
                              dense
                              hide-details
                              :disabled="isCompleted"
                            ></v-checkbox>
                          </div>
                          <label class="overline">consequences</label>
                          <div class="mb-3">{{ type.consequences }}</div>
                        </div>
                        <div class="mb-4 mt-2">
                          <v-textarea
                            v-model="type.comments"
                            label="Risk Comments"
                            rows="2"
                            outlined
                            filled
                            auto-grow
                            dense
                            hide-details
                            :disabled="isCompleted"
                          ></v-textarea>
                          <div class="text-right caption" style="opacity: 0.5" v-if="type.commentsUpdatedOn">
                            {{ formatDateTimeContextual(type.commentsUpdatedOn) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    tasks[taskIndex].documents?.length ||
                    tasks[taskIndex].forms?.length ||
                    tasks[taskIndex].trainings?.length
                  "
                >
                  <div class="overline mt-4">Inline Resources</div>
                  <div class="ml-2">
                    <v-row dense>
                      <v-col cols="12">
                        <div v-if="tasks[taskIndex].documents && tasks[taskIndex].documents.length">
                          <div class="overline">Documents</div>
                          <div
                            class="d-block mr-2 mb-1"
                            v-for="doc in tasks[taskIndex].documents"
                            :key="'doc-' + doc.id"
                          >
                            <v-btn
                              :block="$vuetify.breakpoint.mobile"
                              color="blue darken-2"
                              @click="openDocumentDialog(doc.id)"
                              class="mr-2 white--text custom-btn"
                              style="
                                white-space: normal;
                                height: auto !important;
                                padding-top: 8px !important;
                                padding-bottom: 8px !important;
                              "
                            >
                              <span style="width: 100%; word-break: break-word">{{ doc.name }}</span>
                            </v-btn>
                          </div>
                        </div>
                        <div v-if="tasks[taskIndex].forms && tasks[taskIndex].forms.length">
                          <div class="overline mt-2">Forms</div>
                          <div
                            class="d-block mr-2 mb-1"
                            v-for="form in tasks[taskIndex].forms"
                            :key="'form-' + form.id"
                          >
                            <v-btn
                              :block="$vuetify.breakpoint.mobile"
                              color="green darken-2"
                              @click="
                                openFormDialog(form.id, plannerId, plannerAssignmentId, plannerAssignmentDueDateId)
                              "
                              class="mr-2 white--text custom-btn"
                              style="
                                white-space: normal;
                                height: auto !important;
                                padding-top: 8px !important;
                                padding-bottom: 8px !important;
                              "
                            >
                              <span style="width: 100%; word-break: break-word">{{ form.name }}</span>
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="mb-4">
                  <v-card class="mt-4 pa-4" outlined>
                    <h4 class="mb-2">Update Task Status #{{ taskIndex + 1 }}</h4>
                    {{ task.name }}
                    <br v-if="!$vuetify.breakpoint.mobile" />
                    <v-btn
                      @click="toggleTaskCompletion(taskIndex)"
                      :color="task.completed ? 'success' : 'primary'"
                      class="my-3 mb-5 text-left justify-start"
                      outlined
                      :block="$vuetify.breakpoint.mobile"
                    >
                      <v-icon left>{{
                        task.completed ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline"
                      }}</v-icon>
                      {{ task.completed ? "Completed" : `Task Completed` }}
                    </v-btn>
                    <v-textarea
                      v-model="task.comments"
                      label="Task Comments"
                      rows="1"
                      outlined
                      filled
                      auto-grow
                      dense
                      hide-details
                      :disabled="isCompleted"
                    ></v-textarea>
                    <div class="text-right caption" style="opacity: 0.5" v-if="task.commentsUpdatedOn">
                      {{ formatDateTimeContextual(task.commentsUpdatedOn) }}
                    </div>
                  </v-card>
                </div>
              </div>
              <v-textarea
                v-model="overallComments"
                label="Overall Comments"
                rows="2"
                outlined
                filled
                auto-grow
                hide-details
                :disabled="isCompleted"
              ></v-textarea>
              <div class="text-right caption" style="opacity: 0.5" v-if="overallCommentsUpdatedOn">
                {{ formatDateTimeContextual(overallCommentsUpdatedOn) }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="pt-4 mx-4">Loading...</v-col>
      </v-row>
    </v-container>

    <!-- Fixed Bottom Action Bar -->
    <v-footer
      v-if="!isCompleted"
      fixed
      padless
      app
      class="action-footer"
      style="height: auto; background-color: transparent !important"
    >
      <v-card
        flat
        width="100%"
        class="px-4 py-2"
        style="border-top: 1px solid rgba(0, 0, 0, 0.12); background-color: transparent !important"
      >
        <div class="d-flex align-center justify-space-between" style="background-color: transparent !important">
          <v-spacer></v-spacer>

          <!-- Action Buttons -->
          <div class="d-flex align-center">
            <v-btn
              @click="submitPlanner(false)"
              :disabled="!isPlanLoaded"
              class="mr-2"
              :style="
                $vuetify.theme.dark ? 'background-color: rgba(0,0,0,0.75)' : 'background-color: rgba(255,255,255,0.75)'
              "
              outlined
              color="primary"
            >
              Save
            </v-btn>

            <v-btn
              @click="submitPlanner(true)"
              color="primary"
              :disabled="!isPlanLoaded || !canSubmit"
              :outlined="!isPlanLoaded || !canSubmit"
            >
              Submit
              <template> ({{ Math.round(formProgress) }}%) </template>
            </v-btn>
          </div>
        </div>

        <!-- Progress Bar -->
        <v-progress-linear :value="formProgress" color="primary" class="mt-2" height="2"></v-progress-linear>
      </v-card>
    </v-footer>

    <!-- Optional: Quick Jump to Top -->
    <v-btn
      v-show="showScrollTop"
      @click="scrollToTop"
      fixed
      bottom
      fab
      x-small
      class="ml-4"
      :style="{
        bottom: $vuetify.breakpoint.mobile ? '76px' : '22px',
      }"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    <form-dialog ref="formDialog" />
    <document-dialog ref="documentDialog" />
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
import { DateTime } from "luxon";
import FormDialog from "@/components/forms/FormDialog.vue";
import DocumentDialog from "@/components/documents/DocumentDialog.vue";

import { PlannerService } from "@services";

export default {
  name: "Planner",

  components: {
    FormDialog,
    DocumentDialog,
  },

  mixins: [Helpers],

  props: {
    plannerId: {
      type: [Number, String],
      default: null,
    },
    plannerAssignmentId: {
      type: [Number, String],
      default: null,
    },
    plannerAssignmentDueDateId: {
      type: [Number, String],
      default: null,
    },
    plannerSubmissionId: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    isPlanLoaded() {
      return Object.keys(this.plan).length > 0;
    },
    newSubmission() {
      return {
        plannerId: this.plannerId,
        plannerAssignmentId: this.plannerAssignmentId,
        plannerDueDateId: this.plannerAssignmentDueDateId,
        name: this.planner.name,
        plannerSchemaJson: JSON.stringify(this.plan),
        plannerSubmissionSchemaJson: JSON.stringify(this.constructSubmissionSchema()),
        submit: false,
      };
    },
    currentStatusCode() {
      return this.submission?.status ? this.submission.status : "1";
    },
    currentStatusText() {
      return this.submission?.status ? this.getStatusText(this.submission.status) : "pending";
    },
    isCompleted() {
      return this.currentStatusText.toLowerCase() === "completed";
    },
    canSubmit() {
      return this.formProgress === 100 && !this.hasValidationErrors;
    },
    formProgress() {
      const totalTasks = this.tasks.length;
      const completedTasks = this.tasks.filter((t) => t.completed).length;
      return (completedTasks / totalTasks) * 100;
    },
  },

  data() {
    return {
      loading: false,
      plan: {},
      planner: {},
      assignment: {},
      submission: {},
      dueDate: {},
      metaData: {},
      tasks: [],
      requiresSafetyCompliance: false,
      impactThumbValue: ["Negligible", "Minor", "Moderate", "Major", "Severe"],
      severityThumbValue: ["Insignificant", "Minor", "Moderate", "Major", "Catastrophic"],
      likelihoodThumbValue: ["Rare", "Unlikely", "Possible", "Likely", "Almost certain"],
      overallStatus: "",
      startDate: "",
      completionDate: "",
      overallComments: "",
      overallCommentsUpdatedOn: "",
      showScrollTop: false,
    };
  },
  methods: {
    async loadPlanner(plannerId = null) {
      this.loading = true;
      this.showLoadBar();
      plannerId = plannerId ? plannerId : this.$route.params.plannerId;
      this.loading = true;
      try {
        const response = await PlannerService.getPlanner(plannerId);
        const plan = response.data;
        this.plan = plan;
        this.planner = {
          name: plan.name,
          description: plan.description,
          createdOn: plan.createdOn,
          updatedOn: plan.updatedOn,
        };
        if (plan.metaData) {
          this.metaData = JSON.parse(plan.metaData);
        }
        if (plan.schemaJson) {
          this.tasks = JSON.parse(plan.schemaJson);
          // Initialize additional properties for each task
          this.tasks = this.tasks.map((task) => ({
            ...task,
            comments: "",
            completed: false,
            hazards: task.hazards.map((hazard) => ({
              ...hazard,
              comments: "",
              subTypes: hazard.subTypes.map((subType) => ({
                ...subType,
                trainingConfirmed: false,
                controlMeasuresReady: false,
                mitigationApplied: false,
                ppeInspected: false,
                comments: "",
              })),
            })),
          }));
        }
      } catch (error) {
        console.error("Error loading planner:", error);
        this.$snackbar.showMessage({
          content: "Failed to load Planner",
          color: "error",
        });
      } finally {
        this.loading = false;
        this.hideLoadBar();
      }
    },

    async loadAssignment(assignmentId = null) {
      assignmentId = assignmentId ? assignmentId : this.$route.params.plannerAssignmentId;

      try {
        this.loading = true;
        const r = await PlannerService.getPlannerAssignment(assignmentId);
        const assignment = r.data;

        // Handle safety compliance
        let requiresSafetyCompliance = false;
        if (assignment.dueDates && assignment.dueDates.length > 0) {
          const specificDueDate = assignment.dueDates.find((d) => d.id == Number(this.plannerAssignmentDueDateId));
          if (specificDueDate) {
            requiresSafetyCompliance = specificDueDate.requiresSafetyCompliance || false;
            this.dueDate = specificDueDate;
          } else {
            requiresSafetyCompliance = assignment.dueDates.some((dueDate) => dueDate.requiresSafetyCompliance);
          }
        } else {
          requiresSafetyCompliance = assignment.requiresSafetyCompliance || false;
        }
        this.requiresSafetyCompliance = requiresSafetyCompliance;
        this.assignment = assignment;
      } catch (error) {
        console.error("Error loading planner assignment:", error);
        this.$snackbar.showMessage({
          content: "Failed to load Planner assignment",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async loadSubmission(submissionId) {
      try {
        this.loading = true;
        const r = await PlannerService.getPlannerSubmission(submissionId);
        this.submission = r.data;
        if (this.submission) {
          const submissionData = JSON.parse(this.submission.plannerSubmissionSchemaJson);
          this.overallStatus = submissionData.overallStatus || "";
          this.startDate = submissionData.startDate || "";
          this.completionDate = submissionData.completionDate || "";
          this.overallComments = submissionData.overallComments || "";
          this.overallCommentsUpdatedOn = submissionData.overallCommentsUpdatedOn || "";

          if (submissionData.tasks) {
            submissionData.tasks.forEach((submittedTask) => {
              const task = this.tasks.find((t) => t.id === submittedTask.id);
              if (task) {
                task.completed = submittedTask.status === "Completed";
                task.comments = submittedTask.comments || "";
                task.commentsUpdatedOn = submittedTask.commentsUpdatedOn || "";
              }
            });
          }

          if (submissionData.hazards) {
            submissionData.hazards.forEach((submittedHazard) => {
              this.tasks.forEach((task) => {
                const hazard = task.hazards.find((h) => h.type === submittedHazard.type);
                if (hazard) {
                  hazard.comments = submittedHazard.comments || "";
                  hazard.commentsUpdatedOn = submittedHazard.commentsUpdatedOn || "";
                  submittedHazard.subTypes.forEach((submittedSubType) => {
                    const subType = hazard.subTypes.find((st) => st.name === submittedSubType.name);
                    if (subType) {
                      subType.trainingConfirmed = submittedSubType.trainingConfirmed || false;
                      subType.controlMeasuresReady = submittedSubType.controlMeasuresReady || false;
                      subType.mitigationApplied = submittedSubType.mitigationApplied || false;
                      subType.ppeInspected = submittedSubType.ppeInspected || false;
                      subType.comments = submittedSubType.comments || "";
                      subType.commentsUpdatedOn = submittedSubType.commentsUpdatedOn || "";
                    }
                  });
                }
              });
            });
          }
        }
      } catch (error) {
        console.error("Error loading planner submission:", error);
        this.$snackbar.showMessage({
          content: "Failed to load Planner submission",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    constructSubmissionSchema() {
      const timestamp = DateTime.now().setZone("UTC").toISO();

      // Get existing submission data if available
      const existingSubmission = this.submission?.plannerSubmissionSchemaJson
        ? JSON.parse(this.submission.plannerSubmissionSchemaJson)
        : null;

      return {
        overallStatus: this.overallStatus,
        startDate: timestamp,
        completionDate: this.completionDate,
        tasks: this.tasks.map((task) => {
          const currentTask = existingSubmission?.tasks.find((t) => t.id === task.id);
          return {
            id: task.id,
            status: task.completed ? "Completed" : "Incomplete",
            comments: task.comments || "",
            commentsUpdatedOn:
              currentTask?.comments !== task.comments ? timestamp : currentTask?.commentsUpdatedOn || null,
            completedOn: task.completedOn || null,
          };
        }),
        hazards: this.tasks.flatMap((task) =>
          task.hazards.map((hazard) => {
            const currentHazard = existingSubmission?.hazards.find((h) => h.type === hazard.type);
            return {
              type: hazard.type,
              comments: hazard.comments || "",
              commentsUpdatedOn:
                currentHazard?.comments !== hazard.comments ? timestamp : currentHazard?.commentsUpdatedOn || null,
              subTypes: hazard.subTypes.map((subType) => {
                const currentSubType = currentHazard?.subTypes?.find((st) => st.name === subType.name);
                return {
                  name: subType.name,
                  trainingConfirmed: subType.trainingConfirmed || false,
                  controlMeasuresReady: subType.controlMeasuresReady || false,
                  mitigationApplied: subType.mitigationApplied || false,
                  ppeInspected: subType.ppeInspected || false,
                  comments: subType.comments || "",
                  commentsUpdatedOn:
                    currentSubType?.comments !== subType.comments
                      ? timestamp
                      : currentSubType?.commentsUpdatedOn || null,
                  likelihood: subType.likelihood,
                  impact: subType.impact,
                  severity: subType.severity,
                  requiredTraining: subType.requiredTraining,
                  controlMeasures: subType.controlMeasures,
                  mitigationStrategies: subType.mitigationStrategies,
                  ppe: subType.ppe,
                  consequences: subType.consequences,
                };
              }),
            };
          }),
        ),
        overallComments: this.overallComments,
        overallCommentsUpdatedOn:
          existingSubmission?.overallComments !== this.overallComments
            ? timestamp
            : existingSubmission?.overallCommentsUpdatedOn || null,
        logIts: [],
        formSubmissions: [],
        updatedOn: timestamp,
        completionPercentage: this.calculateCompletionPercentage(),
      };
    },

    calculateCompletionPercentage() {
      const totalTasks = this.tasks.length;
      const completedTasks = this.tasks.filter((task) => task.completed).length;
      return totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
    },

    async submitPlanner(submit) {
      try {
        const submissionData = {
          ...this.newSubmission,
          submit: submit,
          id: this.submission?.id,
        };

        if (this.submission?.id) {
          await PlannerService.patchPlannerSubmission(submissionData);
        } else {
          await PlannerService.createPlannerSubmission(submissionData);
        }

        this.$snackbar.showMessage({
          content: submit ? "Planner submitted successfully" : "Planner saved successfully",
          color: "success",
        });
        this.$router.push("/planners");
      } catch (error) {
        console.error("Error submitting planner:", error);
        this.$snackbar.showMessage({
          content: "Failed to submit planner",
          color: "error",
        });
      }
    },

    async toggleTaskCompletion(taskIndex) {
      const newCompletionStatus = !this.tasks[taskIndex].completed;
      this.$set(this.tasks[taskIndex], "completed", newCompletionStatus);
      if (newCompletionStatus) {
        this.$set(this.tasks[taskIndex], "completedOn", new Date().toISOString());
      } else {
        this.$set(this.tasks[taskIndex], "completedOn", null);
      }
    },

    updateHazardStatus(taskIndex, hazardIndex, subTypeIndex, checked) {
      const subType = this.tasks[taskIndex].hazards[hazardIndex].subTypes[subTypeIndex];
      this.$set(subType, "trainingConfirmed", checked);
    },

    getRiskRating(likelihood, impact) {
      likelihood = Math.max(1, Math.min(5, likelihood));
      impact = Math.max(1, Math.min(5, impact));
      return Math.round(((likelihood * impact - 1) / 24) * 100);
    },

    splitCommaString(input) {
      return String(input)
        .split(",")
        .map((item) => item.trim())
        .filter(Boolean);
    },

    setTrackColor(val) {
      const colors = ["light-blue", "light-green", "yellow", "orange", "red"];
      return colors[Math.min(Math.floor(val), 4)];
    },

    openFormDialog(formId, plannerId, plannerAssignmentId, plannerAssignmentDueDateId) {
      this.$refs.formDialog.openDialog(formId, plannerId, plannerAssignmentId, plannerAssignmentDueDateId);
    },

    openDocumentDialog(documentId) {
      this.$refs.documentDialog.openDialog(documentId);
    },

    handleScroll() {
      this.showScrollTop = window.scrollY > 500;
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },

  async mounted() {
    await this.loadPlanner();
    await this.loadAssignment();
    if (this.$route.params.plannerSubmissionId) {
      this.loadSubmission(this.$route.params.plannerSubmissionId);
    }
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.planner {
  .custom-btn {
    display: block !important;
    width: 100% !important;
  }
  .v-btn--active.no-active:not(:hover)::before {
    opacity: 0 !important;
    background-color: transparent !important;
  }
  .v-btn--active::before {
    background-color: transparent !important;
  }
  .v-item-group.v-bottom-navigation .v-btn {
    min-width: 65px;
  }
  .theme--dark.v-bottom-navigation .v-btn.not-active.v-btn--active {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .theme--light.v-bottom-navigation .v-btn.not-active.v-btn--active {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .nav-bottom {
    border-top: 1px solid #ccc;
    height: auto !important;
    .clock {
      margin: 0 auto;
    }
    .btn-nav {
      padding-top: 7px !important;
      padding-bottom: constant(safe-area-inset-bottom) !important;
      padding-bottom: calc(10px + env(safe-area-inset-bottom)) !important;
    }
    .btn-nav-small {
      font-size: 9px !important;
      font-family: "roboto", sans-serif !important;
      min-width: 50px !important;
    }
    .v-btn:before {
      background-color: transparent;
    }
    @media (max-width: 449px) {
      .btn-nav-small {
        font-size: 8px !important;
      }
    }
  }

  .action-footer {
    background-color: transparent !important;
  }

  .action-footer::before {
    background-color: transparent !important;
  }

  /* Handle iOS safe areas */
  @supports (padding: max(0px)) {
    .footer-card {
      padding-bottom: max(0px, env(safe-area-inset-bottom));
    }
  }
}
</style>
