<template>
  <v-dialog class="document-dialog" v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="d-flex flex-column" height="100vh">
      <v-toolbar dark color="blue darken-2" class="flex-grow-0">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-2">{{ document?.name || "" }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="flex-grow-1 overflow-y-auto pa-0">
        <v-container fluid class="fill-height pa-0">
          <v-row justify="center" class="fill-height ma-0">
            <v-col cols="12" sm="11" md="10" lg="8" xl="6" class="pa-0">
              <div class="document">
                <v-alert
                  v-if="!$online"
                  dense
                  tile
                  color="gray"
                  icon="mdi-alert-circle-outline"
                  class="alert-offline text-headline"
                >
                  {{ $t("t_no_connection") }}
                </v-alert>

                <div class="py-1" v-if="document">
                  <v-card-subtitle class="text-subtitle-1 px-3" v-if="document.description">
                    {{ document.description }}
                  </v-card-subtitle>
                  <v-card-text class="px-1">
                    <v-list
                      flat
                      v-if="documentAssets && documentAssets.length"
                      :class="{ transparent: $vuetify.theme.dark }"
                    >
                      <div v-for="(item, index) in documentAssets" :key="'document-' + index">
                        <DocumentAssetListItem
                          ref="documentAssetListItem"
                          :item="item"
                          :parent="'document'"
                          @getAsset="downloadAsset(item)"
                        />
                      </div>
                    </v-list>
                    <div v-else-if="!loading" class="pa-4">
                      <h3>{{ $t("v_document.t_no_document") || "No documents available" }}</h3>
                    </div>
                  </v-card-text>
                </div>
                <h4 class="pa-3 mt-5" v-if="!loading && !document">{{ $t("v_document.t_no_document") }}</h4>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Helpers from "@/mixins/helpers";
import DocumentAssetListItem from "@/components/documents/DocumentAssetListItem.vue";
import { DocumentService } from "@/services";

export default {
  name: "DocumentDialog",
  components: { DocumentAssetListItem },
  mixins: [Helpers],

  data() {
    return {
      dialog: false,
      loading: false,
      document: null,
      documentAssets: null,
      activeDocument: null,
    };
  },

  methods: {
    async openDialog(documentId) {
      this.dialog = true;
      if (documentId) {
        await this.loadDocument(documentId);
      }
    },

    closeDialog() {
      this.dialog = false;
      this.document = null;
      this.documentAssets = null;
      this.activeDocument = null;
    },

    async loadDocument(documentId) {
      this.loading = true;
      this.showLoadBar();
      try {
        const doc = await DocumentService.getDocument(documentId);
        this.document = doc.data;
        const r = await DocumentService.getDocumentAssets(documentId);
        let assets = r.data;
        this.documentAssets = this.customSort(assets, "description", "name");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.hideLoadBar();
      }
    },

    async downloadAsset(documentAsset) {
      this.showLoadBar();
      let params = {};
      params.documentAssetId = documentAsset.id;
      try {
        const asset = await DocumentService.downloadAsset(params);
        this.activeDocument = asset.data;
        if (documentAsset.mimeType == "application/json") {
          fetch(this.activeDocument.downloadUrl)
            .then((response) => response.json())
            .then((data) => {
              const url = data.url;
              if (this.$platform !== "web") {
                window.location = url;
              } else {
                window.open(url, "_blank");
              }
            });
        } else if (this.activeDocument.downloadUrl) {
          if (this.$platform !== "web") {
            window.location = this.activeDocument.downloadUrl;
          } else {
            const blob = await this.getAssetFromDownloadUrl(this.activeDocument.downloadUrl, null, false);
            window.open(blob, "_blank");
          }
        }
        this.hideLoadBar();
      } catch {
        this.hideLoadBar();
      }
    },

    customSort(array, p, s) {
      return array.sort((a, b) => {
        // Check primary property
        if (!a[p] && b[p]) return 1;
        if (a[p] && !b[p]) return -1;
        if (a[p] && b[p]) {
          const primaryComparison = a[p].localeCompare(b[p]);
          if (primaryComparison !== 0) return primaryComparison;
        }
        // Check secondary property when primary is equal or both null
        if (!a[s] && b[s]) return 1;
        if (a[s] && !b[s]) return -1;
        if (a[s] && b[s]) {
          return a[s].localeCompare(b[s]);
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
.document-dialog {
  .v-dialog--fullscreen .v-card {
    height: 100vh !important;
  }
}
</style>
